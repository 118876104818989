//*// PAGINATION //*//

.pagination {
  display: inline-block;
  padding: 0;
  list-style: none;
  margin: @plone-line-height-computed 0;
  li {
    display: block;
    float: left; // Collapse white-space
    position: relative;
    padding: 0;
    > a, > span {
        padding: @plone-padding-base-vertical @plone-padding-base-horizontal;
        line-height: @plone-line-height-base;
        text-decoration: none;
        background-color: @yellow;
        margin-left: -1px;
      .label {
        font-size: 0.9em;
        font-weight: bold;
        color: #000;

      }
    }
    &.previous {
      > a {
        font-weight: bold;
        margin-left: 0;
      }
      .arrow:before {
        content: '\3c';
      }
    }
    &.next {
      > a {
        font-weight: bold;
      }
      .arrow:after {
        content: '\3e';
      }
    }
    /* wanna hide the label "next ... items"? */
    /* .label {
      display: none;
    } */
    > a {
      &:hover,
      &:focus {
        background-color: @yellow-d;
      }
    }
    &.active > span {
      z-index: 2;
      color: @plone-pagination-active-color;
      background-color: @plone-pagination-active-bg;
      cursor: default;
    }
    &.disabled > span { //just in case, not used in plone
      color: @plone-pagination-disabled-color;
      background-color: @plone-pagination-disabled-bg;
      cursor: default;
    }
  }
}

// mobile pagination only shows next and previous as default behaviour
@media (max-width: @plone-screen-xs-min) {
  .pagination {
    li {display: none;}
    li.previous, li.next {display: inline;}
  }
}

// Fixes for Zeelandia theme

.pagination {
  ul {
    padding-inline-start: 14px;
  }
}



