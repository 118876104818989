.sub-page-header{
    height: 385px;
    position: relative;
    @media @m-l{
      height: 335px;
    }
    @media @m-s{
      height: 250px;
    }
    @media @m-xs{
      height: 225px;
    }
  .header-bg{
    overflow: hidden;
    z-index: 1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin:  0 auto;
    min-height: 100%;
    min-width: 100%;
    text-align: center;
    img{
      top: 0px;
      position: absolute;
      left: 50%;
      max-width: none;
      width: auto;
      transform: translate( -50% , 0);
      height: 100%;
      min-width: 100%;
    }
  }


  .header-content{
    position: absolute;
    top: 15%;
    left: 23%;
    z-index: 1;
    text-align: center;
    margin:  0 auto;
    display: inline-block;
    max-width: 570px;
    @media @m-xl{
      max-width: 500px;
      left: 18%;
    }
    @media @m-m{
      left: 10%;
    }
    @media @m-s{
      width: 55%;
      height: 100%;
      left: 0%;
      top: 0px;
    }
    @media @m-xs{
      width: 55%;
    }

    .header-h{
      background: @yellow-t;
      font-size: 45px;
      line-height: 50px;
      letter-spacing: -2px;
      text-align: left;
      padding: 25px 25px 40px;
      font-weight: 100;
      @media @m-l{
        font-size: 40px;
        line-height: 45px;
        padding: 25px 25px 40px;
      }
      @media @m-s{
        margin: 0px;
        padding: 40px 25px 25px;
        letter-spacing: 0px;
        line-height: 40px;
        font-size: 35px;
        height: 100%;
      }
      @media @m-xs{
        padding: 30px 10px 15px;
        line-height: 33px;
        font-size: 25px;
      }
      strong{
        font-weight: 800;
        letter-spacing: -2px;
      }
    }
  }
}
.sub-page-header.no-image {
  height: 164px;
}