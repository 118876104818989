.searchPage {
  position: relative;

  .actionMenu.activated {
    dd {
      display: block;
    }
  }

  .actionMenu {
    float: right;

    dd {
      display: none;
      position: absolute;
      right: 0;
      top: 35px;
      width: 300px;
      margin-top: 0.5em;
      background-color: #fcfcfd;
      border: 1px solid #e5e5e5;
      padding: 15px;
      z-index: 10;
    }
  }

  .sub-title {
    font-weight: bold;
  }

  .btn-sm {
    padding: 5px 25px 5px 10px;
    margin: 5px 0px;
  }

  .pagination-wrapper {
    margin:  0 0 0.5em 0.3em;
    a {
      margin-right: 0.5em;
    }
    .active {
      font-size: 125%;
      font-weight: bold;
    }
  }
  #search-results-bar {
    #results-count {
      @media @m-xl {
        float: right;
        padding-top: 15px;
        padding-right: 15px;
      }
      @media @m-s {
        clear: both;
        float: initial;
        display: block;
        margin-bottom: .4em;
      }
    }
  }
  .search-all-results {
    border-top: 1px solid @plone-table-border-color;
    margin-top: 10px;
  }
  .search-products, .search-recipes {
    li {
      height: 1.7em;
    }
  }
}
#searchform{
  .input-group{
    max-width: 600px;
    input.searchPage.btn{
    }
    dl.actionMenu {
      margin-bottom: 0px;
    }
    &.input-search > input {
      margin-top: 5px;
    }
    #search-filter-toggle {
      padding: 6px 10px;
      margin: 5px 5px;
      &:after {
        background-image: none;
      }
      &.btn:hover {
        color: white;
        background-color: #286090;
      }
      &.btn:focus {
        color: white;
      }
    }
  }
}
#searchform {
  span.input-group-btn.btn-filter {
    font-size: 1em;

  }
  span.input-group-btn.btn-search input {
    padding-left: 2em;
    margin-left: 0.4em;
  }
}
