@media print {

  /* Bootstrap 3.3.7 print.less added here so we can remove stuff if needed. At the moment just copied because
     completely removing it had too many problems
   */

     *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap components
    .navbar {
        display: none;
    }
    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: #000 !important;
        }
    }
    .label {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: #fff !important;
        }
    }
    .table-bordered {
        th,
        td {
            border: 1px solid #ddd !important;
        }
    }

    // Bootstrap specific changes end


  /* Inhaltsbreite setzen, Floats und Margins aufheben */
  /* Achtung: Die Klassen und IDs variieren von Theme zu Theme. Hier also eigene Klassen setzen */
  #content, #page {
    width: 100%;
    margin: 0;
    float: none;
  }

  /** Seitenränder einstellen */
  @page {
    margin: 1.5cm
  }

  /* Font auf 16px/13pt setzen, Background auf Weiß und Schrift auf Schwarz setzen.*/
  /* Das spart Tinte */
  body {
    font: 10pt "Open Sans", Arial, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }

  h1 {
    font-size: 18pt;
  }

  h2, h3, h4 {
    font-size: 14pt;
    margin-top: 15px;
  }

  /* Alle Seitenumbrüche definieren */
  a {
    page-break-inside: avoid
  }

  blockquote {
    page-break-inside: avoid;
  }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-inside: avoid
  }

  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  table, pre {
    page-break-inside: avoid
  }

  ul, ol, dl {
    page-break-before: avoid
  }

  /* Linkfarbe und Linkverhalten darstellen */
  a:link, a:visited, a {
    background: transparent;
    color: #520;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
  }

  a {
    page-break-inside: avoid
  }

  a:after > img {
    content: "";
  }

  article a[href^="#"]:after {
    content: "";
  }

  /**
   * Eingebundene Videos verschwinden lassen und den Whitespace der iframes auf null reduzieren.
   */
  .entry iframe, ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }

  .embed-youtube, .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden;
  }

  /* Unnötige Elemente ausblenden für den Druck */
  #header-widgets, nav, aside.mashsb-container,
  .sidebar, .mashshare-top, .mashshare-bottom,
  .content-ads, .make-comment, .author-bio,
  .heading, .related-posts, #decomments-form-add-comment,
  #breadcrumbs, #footer, .post-byline, .meta-single,
  .site-title img, .post-tags, .readability {
    display: none;
  }

  /* Wichtige Elemente definieren */
  p, address, li, dt, dd, blockquote {
    font-size: 90%;
  }

  /* Zeichensatz fuer Code Beispiele */
  code, pre {
    font-family: "Courier New", Courier, mono
  }

  ul, ol {
    list-style: square;
    margin-left: 18pt;
    margin-bottom: 20pt;
  }

  li {
    line-height: 1.6em;
  }

  .header-h {
    height: 150px;
  }

  .recipe-img {
    width: 49%;
    float: right;
  }

  .detail-wrapper .col-sm-8 {
    width: 49%;
    float: left;
  }

  .menu-top-fun-wrapper,
  .menu-nav-wrapper,
  .nab-tab,
  .breadcrumb,
  .detail-bottom,
  footer,
  #edit-zone {
    display: none;
    visibility: hidden;
  }

  .documentByLine {
    display: none;
  }

  .plone-toolbar-container {
    display: none;
  }


  /* style print pages for recipes and products */

  #menuMobile {
    .menu-top {
      display: none;
    }
  }
  #menu {
    .logo {
      top: 10px;
      left: 14px;
    }
    .menu-top {
      height: 40px;
      .logo img {
        max-height: 44px;
      }
    }
  }

  .print-page-header .header-h {
    height: auto;
  }
  .recipe-detail-section, .product-detail-section {
    .recipe-h {
    font-size: 16px;
    }
    .method-wrapper {
      margin-top: 42px;
    }
    .recipe-table {
      margin-bottom: 16px;
    }
    .recipe-img {
      padding-top: 10px;
    }
  }

  .no-print {
    display: none;
  }

  .print-page-header .header-h {
    display: block;
    font-size: 26px;
    text-align: center;
    margin: 10px 0px;
  }
  .recipe-detail-section .recipe-h {
    margin: 10px auto 10px;
  }
}

@media screen {
  .no-screen {
    display: none;
  }
}
