body.template-layout {

// nasty edge case
// _findus.less had this statement and it is used on mosaic pages to center h1's in richttext tiles
// on other pages there shouldn't be separaate h1's as we have a styled header. Re-'add this for now
// Zeelandia.nl has multiple h1
// but Leo shouldn't use multiple h1's here, lets give him h2's centered as well. But limit scope to
// the rich text tile as we use h2's extensively in other tiles like the News Item

.mosaic-plone\.app\.standardtiles\.html-tile {
        h1, h2 {
            text-align: center;
        }
    }
    
}

.mosaic-editor-show {
    display: none;
}
body.mosaic-enabled  .mosaic-editor-show {
    display: initial;
}

.mosaic-tile-centered-50 {
    width: 50%;
    margin: auto;
}

.mosaic-tile-centered-33 {
    width: 33%;
    margin: auto;
}


