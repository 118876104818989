#headerSlider{
  position: relative;
  .slider{
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    .slide-wrapper{
      -webkit-backface-visibility: hidden;
      position: absolute;
      display: none;
      width: 100%;
      max-width: 100%;
      height: auto;
      left: 0;
      top: 0;
      position: relative;
    height: 800px;
      @media @m-xl {
        height: 680px;
      }
      @media @m-l{
        height: 500px;
      }
      @media @m-xs{
        height: 425px;
      }
    }
    .slide-wrapper {
      position: relative;
      display: block;
      float: left;
    }
    .slide-bg{
      overflow: hidden;
      z-index: 1;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      margin:  0 auto;
      min-height: 100%;
      min-width: 100%;
      text-align: center;
      img {
        display: block;
        height: auto;
        float: left;
        width: auto;
        border: 0;
        top: 0px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        min-height: 100%;
        min-width: 100%;
        max-width: none !important;

        @media @m-s {
          left: auto;
          right: 0%;
          transform: none;
          display: none;
        }
        &.sm {
          display: none;
          @media @m-s {
            display: block;
            width: auto;
            height: 425px;
          }
        }
      }
    }
  }

  .slide-content-wrapper{
    position: absolute;
    top: 5%;
    left: 15%;
    z-index: 1;
    text-align: center;
    margin:  0 auto;
    display: inline-block;
    max-width: 520px;
    @media @m-xl{
      max-width: 450px;
    }
    @media @m-l{
      left: 23%;
      max-width: 390px;
    }
    @media @m-m{
      left: 13%;
    }
    @media @m-s{
      max-width: 100%;
      width: 100%;
      height: 100%;
      left: 0%;
      top: 0px;
    }

    .content-h{
      background: @yellow-t;
      padding: 35px;
      font-size: 75px;
      letter-spacing: -2px;
      text-align: left;
      padding: 55px 70px 70px 40px;
      line-height: 100px;
      font-weight: 100;
      @media @m-xl{
        padding: 45px 70px 65px 30px;
        line-height: 75px;
        font-size: 60px;
      }
      @media @m-l{
        padding: 25px 70px 50px 20px;
        line-height: 60px;
        font-size: 60px;
      }
      @media @m-s{
        height: 100%;
        width: 58%;
        margin: 0px;
        padding: 40px 20px 20px;
        letter-spacing: 0px;
        line-height: 50px;
        font-size: 48px;
      }
      @media @m-xs{
        padding: 30px 10px 15px;
        line-height: 36px;
        font-size: 33px;
      }
      strong{
        font-weight: 800;
        letter-spacing: -1px;
      }
    }
    .content-desc{
      position: absolute;
      right: -310px;
      bottom: -90px;
      max-width: 400px;
      padding: 15px 10px 5px 20px;
      font-size: 28px;
      background: white;
      font-weight: 100;
      letter-spacing: -1px;
      text-align: left;
      @media @m-xl{
        right: -250px;
        bottom: -70px;
        max-width: 325px;
        font-size: 23px;
      }
      @media @m-l{
        right: -210px;
        bottom: -60px;
        font-size: 18px;
        max-width: 270px;
      }

      @media @m-s{
        font-size: 25px;
        max-width: none;
        left: 0px;
        margin: 0 auto;
        width: 90%;
        right: 0px;
        bottom: 55px;
      }
      @media @m-xs{
        font-size: 20px;
      }
      .content-txt{
        padding: 0px;
        margin: 0px;
      }
      strong{
        font-weight: 800;
      }
    }
    .content-link{
      font-weight: 100;
      text-align: right;
      .btn{
        letter-spacing: 0px;
        font-size: 25px;
        padding: 5px 25px 10px 20px;
        @media @m-xl{
          font-size: 22px;
        }
        @media @m-l{
          font-size: 17px;
        }
      }
    }
  }
  .slider-nav{
    text-align: center;
    .nav-btn{
      margin: 6px;
      display: inline-block;
      width: 18px;
      height: 18px;
      background: @yellow-tt;
      transition: ease all 0.3s;
      &.active{
        background: @yellow;
      }
    }
  }
  .arrow-down{
    position: absolute;
    bottom: -20px;
    right: 0px;
    left: 0px;
    width: 50px;
    text-align: center;
    margin: 0 auto;
    z-index: 6;
    display: inline-block;
  }
}
.rslides1_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 50%;
  left: 0;
  opacity: 0.7;
  text-indent: -9999px;
  overflow: hidden;
  text-decoration: none;
  height: 100%;
  width: 38px;
  background: rgba(100,100,100,1);
  margin-top: -45px;
  }

.rslides1_nav:active {
  opacity: 1.0;
  }

.rslides1_nav.next {
  left: auto;
  background-position: right top;
  right: 0;
  }
