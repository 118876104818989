/* Compensate font-weight: _section_recipe_detail sets the font-weight for products,
   recipes and normal pages to 100, tinymce doesn't have the outer css class so fonts are too
   heavy displayed in the wysiwyg editor.

   We could set font-weight more generic, but this will likely cascade/influence many other sizes

  Also set margin-bottom for normal pages richtext to have proper paragraph separation. This was already 
  visible on the view, but not in TinyMCE
*/
.mce-content-body {
  font-weight: 100;
  p {
    margin-bottom: 0.8em;
  }
}
