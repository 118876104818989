.three-features-section {
  padding: 35px 0px 70px;
  background: @grey;

  .feature-s-h {
    font-size: 40px;
    text-align: center;
    margin: 10px auto 45px;
    @media @m-s {
      font-size: 35px;
      margin: 10px auto;
    }
    @media @m-xs {
      font-size: 30px;
    }
  }
}
.feature-wrapper{
  padding: 8px;
  height: auto;
  background: white;
  position: relative;
  @media @m-s{
    max-width: 475px;
    margin: 50px auto;
  }
  @media @m-xs{
    margin: 25px auto;
  }

  &.special{
    background: @yellow;
    .news-h:after{
      background: white;
    }
    .btn{
      background: @blue;
      color: white;
      &:after{
        background: url('../img/arrow-w.svg') no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
}
.feature-img{
  @media @m-s{
    overflow: hidden;
    width: 100%;
    max-height: 150px;
  }
  img{
    width: 100%;
    @media @m-s{
      position: relative;
      left: 50%;
      transform: translate( -50% , 0);
      width: auto;
    }
  }
}
.feature-desc-wrapper{
  padding: 0px 15px;
  margin-bottom: 60px;
  font-weight: 100;
  @media @m-991{
    padding: 0px 5px;
  }
  @media @m-s{
    padding: 0px 15px 10px;
  }
  @media @m-xs{
    padding: 0px 10px 5px;
  }
  .feature-category {
    padding-top: 20px;
    font-size: 15px;
  }
}
/* mosaic-styles.css update overwrites margin on first hX elements inside mosaic-tile-content */
.mosaic-tile-content {
  h5.feature-h, div.feature-h, .feature-desc-wrapper h5 {
    font-size: 37px;
    line-height: 37px;
    position: relative;
    letter-spacing: -1px;
    margin: 35px auto 10px;
    @media @m-m {
      margin: 25px auto 10px;
      font-size: 30px;
      line-height: 30px;
    }
    @media @m-991 {
      margin: 20px auto 5px;
      font-size: 22px;
      line-height: 27px;
    }

    strong {
      font-weight: 800;
    }

    &:after { //xxxmixin yellow line
      left: -4px;
      content: '';
      position: absolute;
      top: -9px;
      height: 4px;
      background: @yellow;
      width: 56px;
      @media @m-991 {
        width: 35px;
      }
    }
  }
}
.mosaic-tile-content .feature-wrapper.special .feature-desc-wrapper h5:after {
  background: white;
}
.feature-link{
  position: absolute;
  right: 8px;
  bottom: 8px;
  text-align: right;
  .btn {
    font-weight: 100;
  }
}

