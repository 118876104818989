.recipes-section{
  background: white;
  margin: 0px auto 45px;
  .recipes-wrapper{
    margin: 10px auto;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  .recipes-wrapper-inline{
    margin: 10px auto;
  }
  .filters-wrapper{
    margin: 45px 0px 20px;
    @media @m-s{
      text-align: center;
      max-width: 450px;
      margin: 35px auto 0px;
    }
    @media @m-xs{
      margin: 20px auto 0px;
      // max-width: 300px;
    }
    .sort-wrapper{
      position: relative;
      left: 0%;
      @media @m-s{
        left: 0px;
        margin: 15px auto;
      }
      .sort-txt{
        margin-right: 5px;
        font-size: 13px;
        padding: 0px;
        display: inline-block;
        vertical-align: middle;
        @media @m-991{
          font-size: 11px;
        }
        @media @m-s{
          margin-right: 0px;
          font-size: 14px;
          width: 115px;
          text-align: right;
        }
        @media @m-xs{
          // font-size: 13px;
          width: 100%;
          margin-bottom: 5px;
          text-align: center;
        }
      }
      select{
        font-size: 14px;
        vertical-align: middle;
        display: inline-block;
        background: @grey;
        border: none;
        box-shadow: none;
        /* remove width as it doesn't work mulitlingual with different sort-on label widths
        width: 77%; */
        height: 30px;
        @media @m-m{
          width: 72%;
        }
        @media @m-991{
          width: 67%;
        }
        @media @m-s{
          width: 300px;
        }
        @media @m-xs{
          width: 100%;
        }
      }
    }
    .search-wrapper{
      left: 5%;
      width: 95%;
      position: relative;
      @media @m-s{
        width: 100%;
        left: 0px;
        margin: 15px auto;
      }
      input{
        width: 100%;
        border: none;
        box-shadow: none;
        height: 30px;
        background: @grey;
        padding-right: 30px;
        padding-left: 10px;
      }
      .search-btn{
        border: none;
        background: @grey;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        text-decoration: none;
        right: 3px;
        height: 23px;
      }
    }
  }
  .pagination-wrapper{
    position: relative;
    left: 3%;
    color: @black;
    @media @m-s{
      margin: 15px auto;
      left: 0px;
      text-align: right;
    }
    a{
      display: inline-block;
      vertical-align: bottom;
      font-size: 15px;
      padding: 0px 1px;
      text-decoration: none;
      color: @black;
      &.active{
        margin-bottom: -2px;
        font-size: 18px;
        font-weight: 800;
      }
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .subjects-wrapper{
    margin-top: 1.2em;
    padding-bottom: 0em;
    .group {
      display: flex;
      align-items: center;
      margin-bottom: 2em;
    }
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      z-index: 1;
      font-family: @zee-font-family;
    }
    label {
      position: relative;
      margin-right: 1em;
      padding-left: 1.8em;
      padding-right: 1em;
      line-height: 2;
      cursor: pointer;
      z-index: 3;
      font-weight: 100;
      &:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 0.3em;
        left: 0;
        display: block;
        width: 1.3em;
        height: 1.3em;
        border: 1px solid lightgrey;
        background-color: @zeegrey;
        //border-radius: .25em;
      }
    }
    input[type="checkbox"]:checked {

    }
    /* Checked */
    input[type="checkbox"]:checked + label {
      padding-left: 1em;
      color: @blue;
      font-weight: 400;
      &:before {
        top: 0;
        width: 100%;
        height: 2em;
        background: @yellow;
        border: 1px dashed @blue;
        z-index: -1;
      }
    }

    /* Transition */
    label,
    label::before {
      -webkit-transition: .1s all ease;
      -o-transition: .1s all ease;
      transition: .1s all ease;
    }
        
  }
  .recipe{
    padding: 8px;
    /* controleld by background-color class on parent from cms
    background: white; */
    position: relative;
    margin: 20px 5px;
    @media @m-s{
      height: auto;
      margin: 25px 8px;
    }
    @media @m-xs{
      margin: 25px 5px;
    }
    &.new{
      position: relative;
      &:after{
        content: attr(data-content);
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 100;
        padding: 10px 18px;
        font-size: 23px;
        background: @yellow;
        @media @m-m{
          font-size: 20px;
        }
        @media @m-s{
          padding: 7px 12px;
          font-size: 17px;
        }
      }
    }
    &.special{
      background: @yellow;
      .news-h:after{
        background: white;
      }
      .btn{
        background: @blue;
        color: white;
        &:after{
          background: url('../img/arrow-w.svg') no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
    &.subject-vegan{
      .recipe-desc-wrapper {
        min-height: 60px;
      }
      .recipe-desc-wrapper .subject-image{
        float: right;
        height: 60px;
        padding: 1px;
      }
    }
  }
  .recipe-img{
    overflow: hidden;
    width: 100%;
    max-height: 176px;
    img{
      width: 100%;
      height: auto;
      @media @m-s{
        position: relative;
        left: 50%;
        transform: translate( -50% , 0);
        width: auto;
        min-width: 100%;
      }
    }
  }
  .recipe-desc-wrapper{
    padding: 0px 15px;
    margin-bottom: 35px;
    @media @m-991{
      padding: 0px 5px;
    }
    @media @m-s{
      padding: 0px 15px 10px;
    }
    @media @m-xs{
      padding: 0px 10px 5px;
    }
    a {
      text-decoration: none;
    }
  }
  .recipe-h{
    font-size: 30px;
    color: @blue;
    line-height: 30px;
    position: relative;
    letter-spacing: -1px;
    margin: 35px auto 10px;
    @media @m-m{
      margin: 25px auto 10px;
      font-size: 26px;
      line-height: 26px;
    }
    @media @m-991{
      margin: 20px auto 5px;
      font-size: 22px;
      line-height: 27px;
    }
    strong{
      font-weight: 800;
    }
    &:after{ //xxxmixin yellow line
      left: -4px;
      content: '';
      position: absolute;
      top: -9px;
      height: 4px;
      background: @yellow;
      width: 56px;
      @media @m-991{
        width: 35px;
      }
    }
  }
  .recipe-link{
    position: absolute;
    right: 8px;
    bottom: 8px;
    text-align: right;
  }

  .flex-same, .flex-auto {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-items: flex-start;
  }
  .flex-same > * {
      flex: 1 1 auto;
      max-width: 365px;
      min-width: 365px;
      @media @m-md {
        max-width: 297px;
        min-width: 297px;
      }
      @media @m-991 {
        max-width: 334px;
        min-width: 334px;
      }
      @media @m-s {
        max-width: 704px;
        min-width: 320px;
      }
  }
  .flex-same.background-lightgrey  {
    .recipe {
      background: @lightgrey;
    }
    .recipe.special {
      background: @yellow;
    }
  }
  .flex-auto > * {
    flex: 1 1 auto;
  }
  .section-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 10px;
    align-items: stretch;
    justify-items: stretch;
  }
  .masonry.background-white {
    .recipe {
      background: white;
    }
    .recipe.special {
      background: @yellow;
    }
  }
  .masonry.background-lightgrey {
    .recipe {
      background: @lightgrey;
    }
    .recipe.special {
      background: @yellow;
    }
  }
}
