@blue: rgba(35,6,58,1);
@yellow: rgba(255,228,0,1);
@yellow-d: rgba(229, 205 , 0 , 1);
@yellow-t: rgba(255, 228 , 0 , 0.95);
@yellow-tt: rgba(255, 228 , 0 , 0.3);

@grey: rgba(235,234,241,1 );
@zeegrey: @grey;
@footerlightblue: #655176;
@black: rgb(50,50,50);
@grey-d: rgba(100,100,100,1 );
/*  used in section search & very light background grey */
@lightgrey: rgba(235,234,241,0.4 );
@white rgba(255,255,255,1);

@w-xl: 1650px; // resolution just under 1920
@w-l: 1450px; // resolution above 1366
@w-m: 1280px; // ipad big resolution
@w-991: 991px; // ipad small resolution
@w-s: 768px; // ipad small resolution
@w-xs: 550px; // mobile resolution

// @m-xl: ~"only screen and (max-width: @{w-xl} )";
@m-xl: ~"screen and (max-width: @{w-xl} )";
@m-l: ~"screen and (max-width: @{w-l} )";
@m-m: ~"screen and (max-width: @{w-m} )";
@m-991: ~"screen and (max-width: @{w-991} )";
@m-s: ~"screen and (max-width: @{w-s} )";
@m-xs: ~"screen and (max-width: @{w-xs} )";

// stuff coming from plone media queries. this is somewhere on the main content influencing
// section search tiles on 1199-1200 md-lg breakbpoint
@m-md: ~"screen and (max-width: @{plone-screen-md-max} )";

// Override default bootstrap to use Open Sans
@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;


.y-line-after(@height, @width, @top, @left) { // mixin of the header line -> implement
  position: relative;
  &:after{
    transition: ease all 0.3s;
    content: '';
    position: absolute;
    top: @top;
    width: @width;
    height: @height;
    background: @yellow;
    left: @left;
  }
}

.trans(){
  transition: ease all 0.3s;
}
