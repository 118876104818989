@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');
//@import "_fonts.less";
@import "_bootstrap.less";
@import "_config.less";
@import "_easyform.less";
@import "_menu.less";
@import "_subpage_header.less";
@import "_slider.less";
@import "_footer.less";
@import "_section_text.less";
@import "_section_recipes.less";
@import "_section_three_features.less";
@import "_section_two_features.less";
@import "_section_services.less";
@import "_section_recipe_detail.less";
@import "_plone_variables.less";
@import "_plone_search.less";
@import "_plone_tables.less";
@import "_plone_formtabbing.less";
@import "_plone_forms.less";
@import "_plone_alerts.less";
@import "_plone_pagination.less";
@import "_plone_states.less";
@import "_plone_contents.less";
@import "_plone_elements.less";
@import "_plone_portlets.less";
@import "_findus.less";
@import "_print.less";
@import "_social_share.less";
@import "_summary_view.less";
@import "_tinymce.less";
@import "_folder_file_listing.less";
@import "_cookieconsent.less";
@import "_mosaic.less";
//common

// Override default bootstrap to use Open Sans
// @font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// Done in _config.less
@zee-font-family:  @font-family-sans-serif;

body{
  color: @blue !important;
  margin: 0px !important;
  // Don't do padding here, because the toolbar will run over the main content
  // left as comment, maybe there's a good reason for this
  //  padding: 0px !important;
  font-family: @zee-font-family;
  font-weight: 100;
}
td,th{
  font-family: @zee-font-family;
}

.sec-h-sm{
  display: inline-block;
  border-bottom: 2px solid @yellow;
}

.btn{
  background: @blue;
  color: white;
  padding: 10px 25px 10px 20px;
  position: relative;
  transition: ease all 0.3s;
  strong{
    left: 0px;
    transition: ease all 0.3s;
    position: relative;
    font-weight: 800;
  }
  &:after{
    transition: ease all 0.3s;
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(0 , -50%);
    right: 7px;
    height: 25px;
    width: 12px;
    background: url('../img/arrow-w.svg') no-repeat;
    background-position: center;
    background-size: contain;
  }
  &.yellow{
    color: @blue;
    background: @yellow;
    &:after{
      background: url('../img/arrow-b.svg') no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.trans{
    color: @blue;
    background: transparent;
    &:after{
      background: url('../img/arrow-b.svg') no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.small{
    padding: 5px 15px;
    border-radius: 0px;
    &:after{
      width: 10px;
      right: 5px;
      opacity: 0;
    }
    &:hover{
      padding: 5px 18px 5px 15px;
      &:after{
        right: 3px;
        opacity: 1;
      }
      strong{
        left: 0px;
      }
    }

  }
  &:hover{
    &:after{
      right: 2px;
    }
    strong{
      transition: ease all 0.3s;
      position: relative;
      left: 3px;
    }
  }
}

// used in sub-page-header to hide/show an alternative image on small screens
// we don't have any alternativ image stored on the content though...

.rwd-s-show{
  display: none;
  @media @m-s{
    display: block;
  }
}
.rwd-s-hide{
  display: block;
  @media @m-s{
    display: none;
  }
}


.breadcrumb{
  margin: 20px 0px;
  background-color: transparent;
  a.breadcrumb-link{
    display: inline-block;
    position: relative;
    font-weight: 100;
    text-decoration: none;
    color: @black;
    font-size: 13px;
    &:not(:last-child){
      &:after{
        content: ' | ';
        position: relative;
        color: @black;
        top: 0px;
        left: 0px;
        display: inline-block;
        vertical-align: top;
        padding: 0px 0px 0px 2px;
      }
    }
    &.active{
      font-weight: 800;
    }
  }
}

.newsImageContainer {
	float:right;
	margin: 0 0 6px 6px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.17);
	overflow: hidden;

    a {
        display: table;
        background-color: #fafafa;
        }
    a img { display: block; }
	figcaption {
        display: table-caption;
        caption-side: bottom;
		font-size: 12px;
		background: black;
	}
}

.recipe-desc {
    font-size: 16px;
}

p {
  margin: 0 0 4px;
}

.visualClear {
  clear: both;
}

/* copy the bootstrap container def from bootstrap/grid.less
   to copy '.container' on the mosaic version. Mosaic editor
   styles drop down only supports .mosaic-grid-row-cssclass
   type labels :-(
 */

.mosaic-grid-row-container {
  .container-fixed();

  @media screen and (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media screen and (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media screen and (min-width: @screen-lg-min) {
    width: @container-lg;
  }
}


/*
   skip these for the moment, needs some more time if we turn on underline
   on text-decoration we need to visually check pages to make exceptions
   for headlines.

   not specifying #content-core will also change links in mosaic pages
   But with #content-core specifity we'll need to check some overview
   pages, like food_listing item tile titles which will get underlines.

   but specifying #content-core will also add an 'id' specificity weight to
   the assignment, the rest of less is class based (lower spec) and this
   will force other css to use ID's as well to override this again.

   on Documents there is .recipe-detail-section around the main content
   because of diazo template selection and adding container, but not on
   folder views (not $detail set in rules.xml), like photo album.

   to be checked link styles in the sites:
   - linked recipes at the bottom of product pages

*/

// At least reset the default a color to @blue, skip text-decoration underline

a {
  color: @blue;
  &:hover,
  &:active,
  &:visited {
    color: @blue;
  }
}

// Set underline for main content we're sure of is only main content
// Also set a visible underline in RichText TinyMCE editor

.recipe-detail-section a, .mce-content-body a
{
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: underline;
  }
}

/* Make sure we have some white space before the footer starts on normal
   content pages.
 */

footer {
  margin-top: 2.5em;
}

/* also let content core have some margin bottom for 'normal' text page so the text
   doesn't stick to the social print row */

#content-core {
  margin-bottom: 2em;
}


.plonetoolbar-display-view .actionMenuSelected {
  color: yellow !important;
}

// Move search page filter next to search button.
.searchPage{
  .actionMenu{
    dt{
      top: 30px;
      right: 100px;
    }
  }
}

// Hide livesearch form on search page.
// Let livesearch keep the same width and height.
body.template-search .menu-top-fun-wrapper .search {
  width: 206px;
  height: 30px;

  form {
    display: none;
  }
}

// hide social print row, we have moved it into belowcontent and now shows on some
// special pages
body.template-layout .social-print-row,
body.template-food_listing_view .social-print-row {
  display:none;
}

// provide extra margin between paragraphs on normal document_view pages. Empty <p> tags collapse
body.template-document_view  #content-core p {
  margin-bottom: 0.8em;
}


/* Move main container 60pixels down because somehow the mosaic top toolbar overlaps the
 first row of tiles, which you cannot edit otherwise */

body.mosaic-enabled main#main-container {
  margin-top: 60px;
}

.userrole-authenticated {
  .recipes-section .section-state-private {
    border: 2px dotted @plone-default-private-color !important;
  }
  .recipes-section .section-state-pending {
    border: 2px dotted @plone-default-pending-color !important;
  }

}

/* Fix margins on mosaic-grid-row, in plain Plone these are in a
   container/row/col-XY-Z where the col has padding. */

body.template-layout main article {
  padding-left: 15px;
  padding-right: 15px;
}

/* caroussel slides in mosaic with mosaic-with-full get default padding
   I guess because in the mosaic layout editor these are pulled back again with
   negative values, but we don't serve the mosaic css in the frontend theme
 */
body.frontend .mosaic-width-full {
  padding-left: 0px;
  padding-right: 0px;
}

/* cookie-cuttr background button should be Zeelandia Yellow instad of green:
 */

body.frontend {
  .cc-cookies, cc.cookies-error {
    text-shadow: none;
    a.cc-cookie-accept, a.cc-cookie-decline, a.cc-cookie-reset {
      background: @yellow-t;
      color: @blue;
      text-shadow: none;
      margin: 0px 6px;
    }
  }
}

// Use correct Zeelandia font weights in text rows in Mosaic
#content .mosaic-grid-row-dark {
  background-color: #ebeaf1;
  color: #000;
  font-weight: 100;
}

div.mosaic-plone\.app\.standardtiles\.html-tile,
div.mosaic-zeelandia\.tiles\.form-tile {
    font-weight: 100;
    h1,h2,h3 {
      font-weight: 100;
        strong {
        font-weight: 800;
    }
  }
}

// CTA Buttons in the richtext fields
.cta-button {
  .btn();
  .small();
  text-decoration: none;
  font-weight: 200;
  &:link, &:visited, &:hover, &:active {
    color: white;
    text-decoration: none !important;
  }
}
.cta-button-yellow {
  .btn();
  .small();
  .yellow();
  text-decoration: none;
  font-weight: 200;
  &:link, &:visited, &:hover, &:active {
    color: @blue;
    text-decoration: none !important;
  }
}
.cta-button-yellow-big {
  .cta-button-yellow();
  font-size: 20px;
  padding: 10px 30px 10px 25px;
  &:link, &:visited, &:hover, &:active {
    color: @blue;
    font-size: 20px;
    padding: 10px 30px 10px 25px;
    text-decoration: none !important;
  }
}


/*
This last section should be kept somewhat in sync with extrabackend.css
The icons in the toolbar will otherwise work on views, but not on edit forms
extrabackend.css is appended after vanilla barceloneta in backend.xml Diazo rules
*/

/*
Add icons for custom content types from fontello
https://community.plone.org/t/fontello-font-icons-for-p5-toolbar/2000/4
*/

.contenttype-zeelandia-basicrecipe::before,
.contenttype-zeelandia-syncrecipe::before {
  content: '\e816';
}

.contenttype-zeelandia-basicproduct::before,
.contenttype-zeelandia-syncproduct::before {
  content: '\e830';
}

.contenttype-zeelandia-slide::before {
  content: '\e818';
}


/* Allow to swap 2 cells in a Mosaic row so that mobile and medium desktop have different ordering
of the cells. */

body.mosaic-grid {
  .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell:first-child {
    .make-sm-column-push(6);
  }
  .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell:last-child {
    .make-sm-column-pull(6);
  }
}
/* Indicator in editor */
body.mosaic-enabled .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell {
    border: 1px dotted red;
}

/* Fix news item collection display on homepage with existing content tile
   The hX header its margin is removed from the cards by mosaic-styles.css
*/

.mosaic-plone\.app\.standardtiles\.existingcontent-tile .mosaic-tile-content h5:first-child {
  margin-top: 35px;
}

/* Style the Spotler / mailplus.nl mailing list form for zeelandia.nl */
li.mpQuestionTable, .mpFormField li {
  list-style: none;
}
.mpFormField li input {
  margin-left: -2em;
  margin-right: 0.5em;
}
.submitCellSpacer {
  height: 1em;
}
.mpLabelRow .submitButton {
  color: #23063a;
  background: #ffe400;
  border-radius: 0;
  padding: 5px 15px;
}
