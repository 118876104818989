#optout-form {
    .optoutDescription, .customOptOuts {
        margin-bottom: 0.8em;
    }

    .switch-field {
        display: flex;
        margin-bottom: 36px;
        overflow: hidden;
    }
    .switch-title {
        margin-bottom: 1em;
    }
    .switch-field input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
    }
    
    .switch-field label {
        .btn();
        .small();
        background-color: @lightgrey;
        color: @blue;
        font-size: 14px;
        font-weight: 100;
        line-height: 1;
        text-align: center;
        padding: 8px 16px;
        margin-right: -1px;
        transition: all 0.1s ease-in-out;
        min-width: 7em;
        &.selected {
            font-weight: 600;
            text-decoration: underline;
        }
        border-radius: 0;
    }
    
    .switch-field label:hover {
        cursor: pointer;
        padding: 8px 19px 8px 16px;
    }
    
    .switch-field input:checked + label {
        background-color: @yellow;
        box-shadow: none;
    }

    input.submit {
        .btn();
        border-radius: 0;
    }
}

.cc_container .btn_container .cc_btn,
.cc_container .btn_container .cc_btn:visited {
    border-radius: 0;
}