.svg-container {
  width: 80%;
  height: 0;
  padding-top: 36%;
  position: relative;
  margin: 0 auto;
  @media @m-xl {
    padding-top: 40%
  }
  @media @m-l {
    padding-top: 40%
  }
  @media @m-s {
    padding-top: 43%
  }
  @media @m-xs {
    padding-top: 48%
  }
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  margin: -9em -3em -3em -3em;
  @media @m-xl {
    margin: -8em -3em -3em -3em;
  }
  @media @m-l {
    margin: -7em -3em -3em -3em;
  }
  @media @m-s {
    margin: -5em -3em -3em -3em;
  }
  @media @m-xs {
    margin: -3em -3em -3em -3em;
  }

}

#info-box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  border: 2px solid #EEEEEE;
  padding: 8px;
  font-size:89%;
}

#continentname, #continent, .selection-text {
  text-align: center;
}

#country-list #countries {
  min-height: 250px;
  width: 100%;
  height: auto;
}

ul#countries {
  font-size:89%;
  list-style-type: none;
  padding: 1em 3.5em;
  text-align: center;
  line-height: 1.7em;
  a {
    color: rgb(50, 50, 50);
    &:hover {
      text-decoration: underline;
    }
  }

  &.column2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  &.column3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
  &.column4 {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
  }
}
