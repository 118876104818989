.recipe-detail-section, .product-detail-section{
  background: white;
  font-weight: 100;

  .detail-wrapper{
    margin-top: 10px;
    .drift-zoom-pane {
      margin-top: 20px;
      @media @m-s{
        margin: 0px 16px 0px 16px;
      }
    }
  }
  .recipe-h{
    margin: 20px auto 30px;
    font-weight: 800;
    font-size: 35px;
  }
  .recipe-img{
    padding-top: 20px;
    img{
      height: auto;
      width: 100%;
    }
  }
  // titles of linked recipes using related items at the bottom of the product page
  .recipes-wrapper, .recipes-wrapper-inline {
    margin-bottom: 20px;
    .recipe-h {
      font-weight: 600;
      font-size: 22px;
    }
  }
  .recipe-table{
    width: 100%;
    td,th{
      font-size: 14px;
      margin: 2px 0px;
      padding: 5px 0px;
    }
    tr{
      border-bottom: 1px solid @black;
    }
    .star{
      font-weight: 400;
    }
  }
  .recipe-info-txt{
    margin: 33px auto;
    span{
      font-weight: 400;
    }
  }
  .method-wrapper{
    margin-top: 55px;
    .recipe-h{
      margin-bottom: 20px;
    }
  }
  .method-list ul, .method-list ol{
    padding-left: 20px;
    li{
      font-size: 14px;
      padding: 1px 0px;
    }
  }
  .method-info-txt{
    margin-top: 20px;
    font-weight: 800;
  }

}

.detail-bottom{
  margin: 0px auto;
  border-top: 1px solid @black;
  padding-top: 20px;
  position: relative;
  text-align: right;
  .recipes-link{
    float: left;
    a{
      text-decoration: none;
      color: @black;
    }
  }
  .recipes-link,
  .social-wrapper,
  .print-btn{
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 0px auto;
  }
  .print-btn{
    margin-right: 10px;
  }
  .social-wrapper{
    background: @grey;
    padding: 7px 10px;
  }


}

// Backlink to previous page in content social footer only for products and recipes.
// disable > .btn animation because it points the other way, too weird for a back link
.overview-link {
  display: none;
  a {
      vertical-align: middle;
  }
}
.overview-link .btn.yellow {
  margin: 10px 0 0 16px;
  &:after {
    background: initial;
    transition: none;
    transform: none;
  }
}
.overview-link .btn:after {
  background: initial;
  transition: none;
  transform: none;

}

// Only show the << button on recipes and product detail views.

body.template-recipe_view, body.template-product_view {
  .detail-bottom {
    .overview-link {
      display: inline-block;
      vertical-align: middle;
      margin: 0px 0px 0px auto;
      float: left;
      a {
        text-decoration: none;
        color: @black;
      }
    }
  }
}

// Hide the keywords on syncproducts.
body.portaltype-zeelandia-syncproduct #category{
  display: none;
}
