.portaltype-folder {
  .pagination-wrapper {
    margin:  0 0 0.5em 0.3em;
    a {
      margin-right: 0.5em;
    }
    .active {
      font-size: 125%;
      font-weight: bold;
    }
  }

  .feature-sizeinfo{
    position: absolute;
    left: 24px;
    bottom: 15px;
    text-align: left;
  }

  .feature-imagepreview{
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: left;
    margin-bottom: 10px;
  }

  .feature-description {
    min-height: 16px;
  }

  .recipe-section .recipe-desc-wrapper {
    margin-bottom: 40px;
    h3.file-listing-title {
      margin-top: 0px;
      height: 2.6em;
      font-size: 1em;
      font-weight: 400;
    }
  }

}
