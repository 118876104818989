.portaltype-easyform, .mosaic-zeelandia\.tiles\.form-tile   {
  label {
    .label {
          color: @blue;
      font-size: 0.9em;

    }
  }

  fieldset  { margin: 20px 0;}


  span.formHelp {
    display: block;
    font-weight: lighter;
    font-style: italic;
    clear: both;
  }


  textarea {
    width: 40em;
    height: 6em;
    @media @m-s {
      width: 25em;
    }
  }
  input[type="checkbox"], input[type="radio"] {
    width: initial !important;
  }


  .radio-widget {
    width: initial;
  }

  .formControls {
    margin-bottom: 1em;

    input {
      margin-top: 1em;
    }
  }

  // description sticks to the top of the form
  // description & richtext tune font/weight as on pages.
  // something in the easyform schema rendering, fix here for now.
  .form {
    p.discreet {
      font-weight: 400;
      color: rgb(35, 6, 58);
    }
  }
  form#form {
    margin-top: 1em;
    #formPrologue, #formEpilogue{
      font-weight: 100;
    }
  }

  .formControls .submit-widget {
    .cta-button-yellow();
    &:link, &:visited, &:hover, &:active {
      color: @blue;
      text-decoration: none !important;
    }
    content:after " ";
  }

  
}

// Specific for full content forms

.portaltype-easyform {
  input, textearea {
    width: 50% !important;
    @media @m-s {
      width: 100%;
    }
  }
}

.mosaic-zeelandia\.tiles\.form-tile {
  input, textearea {
    width: 100% !important;
  }
}