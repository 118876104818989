body.template-summary_view {

  h1.documentFirstHeading {
    text-align: left;
    color: #23063a !important;
  }
  .documentDescription {
    text-align: left;
    margin-bottom: 20px;
  }
  .feature-link {
    position: relative;
    display: block;
    float: right;
    padding-left: 4px;
    padding-top: 10px;
    @media @m-s {
      float: left;
      margin-top: 10px;
      margin-left: 4px;
    }
  }
  article.tileItem {
    border-top: 1px solid #23063a;
    margin-top: 16px;
    padding-top: 16px;
    h2.tileHeadline {
      margin-top: 10px;
      font-size: 18px;
      display: inline;
      @media @m-s {
        display: block;
        clear: both;
      }
      a {
        color: #23063a !important;
        text-decoration: none;
      }
    }
  }
  .tileBody {
    margin-top: 8px;
  }
}
