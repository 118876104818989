.text-section{
  text-align: center;
  margin: 80px auto 80px;
  @media @m-m{
    margin: 60px auto 60px;
  }
  @media @m-s{
    margin: 40px auto 40px;
  }
  .text-s-h, h1, h2 {
    font-weight: 100;
    font-size: 54px;
    letter-spacing: -3px;
    margin-top: 0px;
    margin-bottom: 35px;
    @media @m-m {
      font-size: 44px;
    }
    @media @m-s {
      letter-spacing: -2px;
      font-size: 34px;
      margin: 0px 0px 15px;
    }
    @media @m-xs {
      letter-spacing: -1px;
      font-size: 30px;
    }

    &.large{
      letter-spacing: -9px;
      font-size: 125px;
      margin: 5px auto 20px;
      @media @m-l{
        font-size: 95px;
      }
      @media @m-m{
        letter-spacing: -2px;
        font-size: 80px;
      }
      @media @m-s{
        font-size: 65px;
      }
      @media @m-xs{
        letter-spacing: -1px;
        font-size: 45px;
      }
    }
    strong{
      font-weight: 800;
    }
  }
  .text-s-txt, p {
    margin: 0 auto;
    font-weight: 100;
    font-size: 21px;
    max-width: 910px;
    @media @m-m{
      padding: 0px 15px;
      font-size: 17px;
    }
    @media @m-xs{
      font-size: 16px;

    }
    &.narrow{
      font-size: 17px;
      max-width: 735px;
      @media @m-m{
        font-size: 15px;
      }

    }
  }
  }//text-section
