header{
  position: relative;
}
#menu{
  display: block;
  @media @m-s{
    display: none;
  }
}
#menuMobile,
#menu{
  position: relative;
  .menu-top{
    color: black;
    position: relative;
    height: 105px;
    @media @m-l{
      height: 85px;
    }
    &.container{
      @media @m-m{
        width: 95% ;
      }
    }
  }
  .logo{
    position: absolute;
    left: 45px;
    top: 35px;
    @media @m-l {
      top: 20px;
    }
    @media @m-s {
      top: 0px;
      left: 0px;
    }
    img {
      max-width: 225px;
      max-height: 62px;
    }
  }
  .menu-top-fun-wrapper{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0,-50%);
    text-align: right;
    .search,
    .small-nav-wrapper,
    .lang-wrapper{
      display: inline-block;
    }
    .small-tab{
      display: inline-block;
      a{
        transition: ease all 0.6s;
        color: black;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding: 5px 10px;
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        border-bottom: 2px solid white;
        @media @m-m{
          padding: 5px;
        }
        &:hover{
          border-bottom: 2px solid @yellow;
        }
      }
    }
    .small-tab + .small-tab a {
       border-left: 2px solid black;
    }
    .lang-wrapper{
      margin: 0px 10px;
      cursor: pointer;
      &:hover{

      }
      .lang {
        font-weight: 400;
        display: inline-block;
        //text-transform: capitalize;
      }
      .arrow-btn{
        display: inline-block;
        position: relative;
        height: 10px;
        width: 10px;
        &:before,
        &:after{
          background: black;
          position: absolute;
          content: '';
          width: 6px;
          height: 1px;
          top: 35%;
          transform: rotate(45deg) ;
          left: 0px;
        }
        &:before{
          transform: rotate(-45deg) ;
          left: 4px;
        }
      }
    }
    .search{
      margin-left: 10px;
      position: relative;
      @media @m-m{
        width: 150px;
      }
      .search-btn{
        cursor: pointer;
        content: url('../img/search-ic.svg');
        position: absolute;
        top: 55%;
        transform: translate(0,-50%) scale(0.9);
        left: 5px;
        height: 60%;
      }
      input{
        font-weight: 400;
        height: 30px;
        padding-left: 25px;
        border: 0.5px solid @blue;
        @media @m-m{
          width: 100%;
        }
      }

    }
  }

  .menu-nav-wrapper{
    background: @grey;
    text-align: center;
    .nav-tab{
      margin-right: -5px;
      text-align: left;
      position: relative;
      display: inline-block;
      vertical-align: top;
      &:not(:first-child){
        border-left: 0.5px solid grey;
      }
      &.active,
      &:hover{
        a span{
          left: 5px;
        }
        a:after{
          width: 100%;
        }
      }
      a{
        font-weight: 100;
        transition: ease all 0.3s;
        position: relative;
        color: @black;
        display: block;
        font-size: 14.5px;
        min-width: 135px;
        width: auto;
        padding: 12px 15px 12px 15px;
        text-decoration: none;
        @media @m-l{
          min-width: 75px;
          padding: 10px 15px;
        }
        @media @m-m{
          font-size: 13.5px;
          min-width: 75px;

          width: auto;
          padding-right: 15px;
        }
        @media @m-991{
          padding: 10px 10px;
          font-size: 12px;
          min-width: 0px;
        }
        @media @m-s{
          font-size: 12.5px;
          min-width: 60px;
          padding-right: 12px;
        }
        span{
          transition: ease all 0.3s;
          position: relative;
          left: 0px;
        }

        &:after{
          transition: ease all 0.3s;
          content: '';
          width: 0%;
          bottom: 0px;
          position: absolute;
          height: 4px;
          background: @yellow;
          left: 1px;
        }
      }
    }
  }
}

#menuMobile{
  height: 55px;
  position: relative;
  display: none;
  @media @m-s{
    display: block;
  }
  &.show{
    #menuOverflow{
      width: 100%;
      opacity: 1;
      display: inline-block;
      z-index: 99;
      left: 0px;
      height: 100vh;
    }
  }
  .menu-top{
    padding: 0px 3%;
    position: fixed;
    width: 100% !important;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 99;
    background: white;
    height: 55px !important;
  }
  .logo{
    transform: none;
    position: relative;
    height: 100%;
    bottom: 0px;
    img{
      top: 7px;
      position: relative;
      max-width: 170px;
      @media @m-s{
        top: 15px;
        max-width: 120px;
      }
    }
  }
  .menu-nav-wrapper{
    position: relative;
    top: 45%;
    transform: translate(0,-50%);
    background: transparent;
    @media @m-s{
      transform: none;
      top: 0px;
    }
    .nav-tab{
      border: none !important;
      text-align: center;
      margin: 10px auto;
      display: block;
      @media @m-s{
        margin: 5px auto;
      }
      a{
        font-size: 25px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        @media @m-s{
          font-size: 16px;
        }
        &:after{
          height: 2px;
          background: @black;
        }
      }
    }

  }

  .menu-toggle-wrapper{
    position: absolute;
    top: 15px;
    right: 15px;
    position: inline-block;
    vertical-align: middle;
  }
  #menuToggle{
    width: 35px;
    height: 35px;
    cursor: pointer;
    .line{
      margin: 6px auto;
      position: relative;
      display: block;
      width: 100%;
      height: 2px;
      background: @blue;
    }
  }

  #menuOverflow{
    top: 55px;
    z-index: -20;
    left: -200px;
    background: @yellow;
    width: 0%;
    opacity: 0;
    position: relative;
    transition: ease all 0.3s;
  }

  .search{
    text-align: center;
    display: block;
    margin: 20px auto;
    position: absolute;
    bottom: 10%;
    right: 0px;
    left: 0px;
    width: 220px;
    @media @m-s{
      bottom: 0%;
      position: relative;
    }
    .search-btn{
      cursor: pointer;
      content: url('../img/search-ic.svg');
      position: absolute;
      top: 55%;
      transform: translate(0,-50%) scale(0.9);
      left: 5px;
      height: 60%;
    }
    input{
      font-weight: 400;
      height: 30px;
      padding-left: 25px;
      border: 0.5px solid @blue;
      @media @m-m{
        width: 100%;
      }
    }

  }
}
