.mosaic-tile-content .two-features-section{
  padding: 40px 0px;
  background: @grey;
  text-align: center;
  position: relative;
  .feature-wrapper{
    height: 425px;
    text-align: left;
    padding: 7px;
    background: transparent;
    @media @m-xl {
      height: 425px;
    }
    @media @m-l {
      height: 425px;
    }
    @media @m-m {
      height: 415px;
    }
    @media @m-991 {
      height: 330px;
    }
    @media @m-s{
      margin: 35px auto;
      max-width: 475px;
      height: auto;
    }
    @media @m-xs{
      margin: 10px auto;
    }
    &.special{
      background: @yellow;
      .feature-h:after{
        background: white;
      }
      .btn{
        background: @blue;
        color: white;
        &:after{
          background: url('../img/arrow-w.svg') no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
    &.large{
      background: white;
      .feature-desc-wrapper,
      .feature-img{
        width: 49%;
        display: inline-block;
        vertical-align: top;
        height: 100%;
        overflow: hidden;
        @media @m-xs{
          max-width: 100%;
        }
        @media @m-s{
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
      .feature-h{
        font-size: 50px;
        line-height: 50px;
        padding: 20px 0px;
        @media @m-m{
          font-size: 40px;
          line-height: 40px;
        }
        @media @m-991{
          padding: 20px 0px 10px;
          line-height: 30px;
          font-size: 25px;
        }
        &:after{
          top: 7px;
        }
      }
    }
  }
  .feature-img{
    position: relative;
    width: 100%;
    height: auto;
    @media @m-s{
      overflow: hidden;
      max-height: 200px;
    }
    @media @m-xs{
      max-height: 150px;
    }
    img{
      width: 100%;
      height: auto;
      @media @m-s{
        position: relative;
        transform: translate(-50% , 0%);
        left: 50%;
        margin: 0 auto;
        width: auto;
      }
    }
  }
  .feature-desc-wrapper{
    padding: 0px 25px;
    margin-bottom: 35px;
    @media @m-991{
      padding: 0px 15px;
    }
    @media @m-s{
      margin-bottom: 15px;
    }
  }
  .feature-category{
    padding-top: 20px;
    font-size: 15px;
  }
  .feature-h{
    font-weight: 100;
    font-size: 37px;
    position: relative;
    letter-spacing: -1px;
    padding: 15px 0px 0px;
    line-height: 1.1em;
    margin: 30px 0px 5px 0px;
    @media @m-m{
      font-size: 30px;
    }
    @media @m-991{
      letter-spacing: -1px;
      font-size: 23px;
    }
    strong{
      font-weight: 800;
    }
    &:after{ //xxxmixin yellow line
      left: 0px;
      content: '';
      position: absolute;
      top: 0px;
      height: 4px;
      background: #ffe400;
      width: 57px;
    }
  }
  .feature-txt, p {
    font-size: 14px;
    font-weight: 100;
  }
  .feature-link{
    position: absolute;
    bottom: 7px;
    right: 24px;
    text-align: right;
    @media @m-s{
      left: inherit;
      right: 0px;
      bottom: 0px;
      margin: 0px 0px 0px auto;
      position: relative;
    }
  }
}
