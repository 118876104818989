.service-section {
  padding: 45px 0px;

  .service-txt-wrapper {
    padding-right: 50px;
    @media @m-s {
      padding-right: 0px;
    }
  }

  .service-s-h {
    font-size: 70px;
    line-height: 60px;
    position: relative;
    letter-spacing: -5px;
    margin: 20px auto 40px;
    @media @m-m {
      font-size: 55px;
      line-height: 50px;
    }
    @media @m-s {
      margin: 10px auto 20px;
      font-size: 45px;
      line-height: 45px;
    }

    strong {
      font-weight: 800;
    }

    &:after { //xxxmixin yellow line
      left: 3px;
      content: '';
      position: absolute;
      top: -5px;
      height: 5px;
      background: @yellow;
      width: 40px;
    }
  }

  .service-s-txt {
    font-weight: 100;
    font-size: 17px;
    line-height: 24px;
  }

  .btn-wrapper {
    text-align: right;
  }
}
.service-links-wrapper {
  margin-top: 25px;

  .service-link {
    color: @blue;
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.7);
    padding: 7px 10px 3px 75px;
    margin: 5px auto;
    position: relative;

    &:hover {
      text-decoration: none;

      &:before {
        left: 10px;
      }

      .link-h:after {
        width: 45px;
        left: 0px;
      }
    }

    &:last-child {
      padding: 7px 10px 7px 75px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    &:before {
      transition: ease all 0.3s;
      content: url('../img/arrow-left.png');
      position: absolute;
      top: 13%;
      left: 0px;

    }

    .link-h {
      font-weight: 800;
      font-size: 25px;
      margin: 2px 0px 0px;
      padding: 0px;
      letter-spacing: -1px;
      .y-line-after(4px, 0px, -4px, 0px);
      @media @m-m {
        .y-line-after(2px, 0px, -4px, 0px);
        font-size: 20px;
      }
      @media @m-s {
        font-size: 17px;
      }
    }

    .link-txt {
      font-size: 15px;
      line-height: 22px;
      font-weight: 100;
      margin: 0px;
      padding: 0px;
      @media @m-m {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}
