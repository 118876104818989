/* Extra Plone Styling on top of copied less files from barceloneta which are included
   in zeelandia_theme before _plone_elements.less
*/

body.template-login_form #content,
body.template-login_failed #content,
body.template-logged_out #content,
body.template-login #content,
.pb-ajax #login-form{
  text-align: center;
  margin-top: 2em;
  .footer{ margin-top: 1em;}
}
#fieldset-openid-login input, #login-form input {
  font-size: 150%;
}

#fieldset-openid-login label, #login-form label {
  margin-right: 0.5em;
  padding-top: 0.2em;
  text-align: right;
  width: 10em;
  display: inline-block;
}

#login-form .field {
  clear: both;
  margin-bottom: 1.5em;
}



#parent-fieldname-text img {
  max-width: 100%;
}

// clear floats:
.entries::before,
#content article.entry::after,
#relatedItemBox ul li::after,
#searchresults ul li::after  {
  content: " ";
  display: block;
  clear: both;
  height:2px;  //allow minimal vertical space for thumbs
  visibility: hidden;
}

// PhotoAlbumEntry from Barceloneta

.photoalbumEntryWrapper {
  display: block;
}
.photoAlbumEntry {
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}
.photoAlbumEntry a {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: 400;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: @grey;
  width: 220px;
  height: 240px;
}
.photoAlbumEntry img {
  max-width: 200px;
  max-height: 200px;
}

.photoAlbumEntryTitle {
  display: inline-block;
  width: 100%;
}

.image-product {
  margin: 0;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: inline-block;
}
.image-product figcaption {
  font-size: 12px;
  background: #fcfcfd;
  margin: 6px 12px;
}

// Extra @@search view styling on top of _plone_search.less

#rss-subscription {
  display: none;
}
#search-results li {margin: @plone-padding-base-horizontal 0;
  .croppedDescription {margin-bottom: 0;}
}

#searchform {
  input.searchPage.btn {
    padding: 6px 25px 6px 10px;
  }
  .input-group {
    margin-left: auto;
    margin-right: auto;
  }
}

// Fix labels in mosaic editor modal tile edit screens
// we have both zeelandia theme and barceloneta css here

.plone-modal .label {
    color: black;
    font-weight: normal;
    font-size: 100%;
}

/* Plain table styling from Plone 4. There are quite some inline Plone 4 styles still present
   in the Plone 5 sites because they were migrated. These were specifically copied from sunburst
   for zeelandia-international. listing is still present in Plone 5.
 */

table.plain {
  border-spacing: 0;
  border-collapse: collapse;
  td, th {
    padding: 0.5em 1em;
    vertical-align: top;
    border: 1px solid #ddd;
  }
  th {
    text-align: left;
  }
}
